import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const DataSolutions = () => (
  <Layout>
    <SEO title="Data Solutions" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-orange">Data Solutions</h1>
        <div className="row">
          <div className="col-lg-8">
            <p>
              Gray Hawk Land Solutions provide services that are at the
              forefront of data processing technology. We make full use of
              advanced artificial intelligence to handle digitized information.
              Not only can you make sure that your data are well organized, you
              can also gather crucial information to improve your business
              processes.
            </p>
            <p>
              We fully understand that data management can be tedious and that
              you may not have time to do a deep dive into all the digital and
              physical files you have in the office. That is why we made our
              data solutions available to cater to your needs and budget.
            </p>
          </div>
          <div className="col-lg-4">
            <img src={hawkeye} className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DataSolutions
